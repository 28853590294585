<template>
  <ion-page>
    <ion-content style="--background: var(--ion-color-light)">
      <ion-header>
        <ion-toolbar>
          <ion-title>What's your complaint?</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-card class="main-card">
        <ion-card-header>
          <router-link :to="{ path: `/member/consult` }"
            ><img src="assets/icon/back.svg" width="26" style="float: left"
          /></router-link>

          <ion-card-title>What's your complaint?</ion-card-title>
          <p>Tell the doctor what's bothering you.</p>
        </ion-card-header>

        <ion-card-content>
          <form ref="form" @submit.prevent="submit">
            <p v-if="errors.length" style="background: var(--ion-color-secondary-tint);padding: 10px;border-radius: 9px;margin-bottom: 50px;">
              <b>Please correct the following error(s):</b>
              <ul>
                <li v-for="error in errors" :key="error" style="text-align: left;">{{ error }}</li>
              </ul>
            </p>

            <span class="p-float-label">
              <Textarea
                v-model="form.complaint"
                :autoResize="true"
                rows="4"
                cols="30"
                style="min-height: 125px"
                required
              />
              <label for="compaint">Main Complaint</label>
            </span>

            <span class="p-float-label">
              <InputText
                id="mobile"
                type="text"
                v-model="form.mobile"
                required
              />
              <label for="mobile">Mobile Number</label>
            </span>

            <!-- <div class="document-label">
              <label for="document" class="document-label">Add a picture of relevant area (optional)</label>
            </div> -->

            <!-- <div id="drop-area">
              <div id="upload">
                <p class="drag-text">
                  Drag a document here 
                  <br />
                  or 
                  <br />
                </p>
                <input
                  type="file"
                  id="fileElem"
                  accept="image/png, image/jpeg"
                  @change="handleFilesSelected"
                />
                <label class="drop-button" for="fileElem">Select from device</label>
              </div>

              <div id="clear">
                <p id="file" class="drag-text"></p>
                <label class="drop-button" @click="clear()">Clear</label>
              </div>
            </div> -->

            <ion-button
              style="width: auto"
              size="small"
              type="submit"
              shape="round"
              >Start Video Chat</ion-button
            >
            <!-- <a href="" class="router-button unselected">Start Telephonic Consult</a> -->
          </form>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
} from "@ionic/vue";

export default {
  name: "Complaint",
  components: {
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonCard,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonPage,
    IonContent,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      errors: [],
      form: {
        complaint: null,
        mobile: null,
        image: null,
        user_id: null,
      },
    };
  },
  ionViewDidEnter() {
    this.form.user_id = this.user.id;
  },
  methods: {
    submit: function () {
      this.errors = [];

      // if (this.form.mobile.length != 10) {
      //   this.errors.push("Mobile number is not the correct length");
      // }else{
        this.$store.dispatch("StartConsult", this.form);
      // }
    },
    // clear: function () {
    //   document.getElementById("clear").style.display = 'none';
    //   document.getElementById("upload").style.display = 'block';
    // },
    // handleFilesSelected: function (event) {
    //   let files = event.target.files;
    //   ([...files]).forEach(this.uploadFile)
    // },
    // uploadFile: async function(file) {
    //    this.form.image = await this.toBase64(file);

    //   document.getElementById("upload").style.display = 'none';
    //   document.getElementById("clear").style.display = 'block';
    //   document.getElementById("file").textContent = file['name'];
    // },
    // async toBase64(file) {
    //   return await new Promise((resolve, reject) => {
    //     const reader = new FileReader();

    //     reader.readAsDataURL(file);
    //     reader.onload = () => resolve(reader.result);
    //     reader.onerror = error => reject(error);
    //   });
    // }
  },
  // mounted() {
  //   let dropArea = document.getElementById("drop-area");
  //   ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
  //     dropArea.addEventListener(eventName, preventDefaults, false);
  //   });

  //   function preventDefaults(e) {
  //     e.preventDefault();
  //     e.stopPropagation();
  //   }

  //   ['dragenter', 'dragover'].forEach(eventName => {
  //     dropArea.addEventListener(eventName, highlight, false)
  //   });

  //   ['dragleave', 'drop'].forEach(eventName => {
  //     dropArea.addEventListener(eventName, unhighlight, false)
  //   });

  //   function highlight() {
  //     dropArea.classList.add('highlight')
  //   }

  //   function unhighlight() {
  //     dropArea.classList.remove('highlight')
  //   }

  //   dropArea.addEventListener('drop', handleDrop, false);

  //   function handleDrop(e) {
  //     let dt = e.dataTransfer
  //     let files = dt.files

  //     handleFiles(files);
  //   }

  //   var that = this;
  //   function handleFiles(files) {
  //     ([...files]).forEach(that.uploadFile)
  //   }
  // },
};
</script>

<style scoped src="../../../theme/drag&drop.css">
</style>
<style scoped>
ion-card-title {
  font-weight: 700;
}

.main-card {
  margin-top: 12px;
}

p {
  font-size: 16px;
  line-height: 24px;
  color: var(--ion-color-primary);
}

.option {
  border: 1px solid #c7c7c7;
  height: 73px;
  width: 25%;
  border-radius: 7px;
  margin-right: 23px;
  float: left;
}

.selected {
  border: 2px solid var(--ion-color-primary);
}

form {
  padding-top: 20px;
}

h1 {
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  color: #000000;
  margin-top: 34px;
  margin-bottom: 35px;
}

.p-float-label {
  margin-bottom: 27px;
}
</style>